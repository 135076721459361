import { browserTracingIntegration, init, replayIntegration } from '@sentry/vue'
import { createPinia } from 'pinia'
import { registerSW } from 'virtual:pwa-register'
import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

import orderTrackingRoutes from '@/modules/order-tracking/router'
import { initI18n } from '@/plugins/i18n'
import { init as initOpenTelemetry } from '@/plugins/openTelemetry'
import { init as initVeeValidate } from '@/plugins/veeValidate'
import DateFormat from '@common/components/_ui/DateFormat.vue'
import Loader from '@common/components/_ui/Loader.vue'
import { SENTRY_DSN, SENTRY_ENV, SENTRY_RELEASE, IS_LOCAL_ENV } from '@common/config'
import { gtm } from '@common/services/gtm'
import App from '@common/views/App.vue'
import { useOrderTrackingStore } from '@modules/order-tracking/store'

export async function initVue() {
  const app = createApp(App)
  const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: orderTrackingRoutes,
  })

  app.use(createPinia())
  useOrderTrackingStore()

  if (!IS_LOCAL_ENV) {
    initOpenTelemetry()
  }

  init({
    app,
    dsn: SENTRY_DSN,
    logErrors: true,
    environment: SENTRY_ENV,
    release: SENTRY_RELEASE,
    denyUrls: [
      'clarity.ms',
      'cookiebot.com',
      'criteo.com',
      'doubleclick.net',
      'meiro.io',
      'webchat.drmax.sk',
    ],
    integrations: [
      browserTracingIntegration({
        router,
      }),
      replayIntegration(),
    ],

    tracePropagationTargets: ['localhost', 'order-tracking', /^\//],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })

  const i18n = await initI18n()
  initVeeValidate(i18n)

  router.afterEach(() => {
    gtm.update()
  })

  app.component('DateFormat', DateFormat)
  app.component('Loader', Loader)

  app.use(router)
  app.use(i18n)

  registerSW({
    immediate: true,
    // onNeedRefresh() {},
    // onOfflineReady() {},
    // onRegisterError() {},
  })

  app.mount('#app')
}
