import { useApiRequest } from '@dr-max/vue-http'
import { defineStore } from 'pinia'
import { computed } from 'vue'

import { options } from '@/plugins/vueHttp'
import apiResource from '@/resources/api'
import { MUTATION_CONFIG } from '@common/config'

export const useOrderTrackingStore = defineStore('orderTracking', () => {
  const { apiCall: fetchOrder, status: orderStatus } = useApiRequest(
    apiResource.fetchOrder,
    options,
  )

  const order = computed(() => {
    const { hasSuccessResponse, data } = orderStatus.value
    return hasSuccessResponse ? data : null
  })

  const shipping = computed(() => {
    if (!order.value) return

    const { pickupPlace, pharmacy } = order.value
    const { parcelHistory, parcelExpireAt, isParcelInFinalStatus } =
      order.value.shipping.carrier ?? {}

    let shippingDetails = null
    if (pickupPlace) {
      shippingDetails = getPickupPlaceShipping(order.value)
    } else if (pharmacy) {
      shippingDetails = getPharmacyShipping(order.value)
    } else {
      shippingDetails = getUserShipping(order.value)
    }

    return {
      ...shippingDetails,
      parcelHistory,
      parcelExpireAt,
      isParcelInFinalStatus,
    }
  })

  const pharmacy = computed(() => {
    if (!order.value) return

    return order.value.pharmacy
  })

  const pickupPlace = computed(() => {
    if (!order.value) return

    return order.value.pickupPlace
  })

  async function fetchOrderTracking({ orderId, customerEmail }) {
    await fetchOrder({
      orderId,
      customerEmail,
    })
  }

  return {
    shipping,
    pharmacy,
    pickupPlace,
    orderStatus,
    fetchOrderTracking,
  }
})

function getPharmacyShipping(order) {
  return {
    url: `${MUTATION_CONFIG.info.link.pharmacies}/${order.pharmacy.urlKey}`,
    method: order.shipping.method,
    addressText: `${order.pharmacy.street}, ${order.pharmacy.zip}, ${order.pharmacy.city}`,
  }
}

function getPickupPlaceShipping(order) {
  const { city, description, street, zipCode } = order.pickupPlace.location

  return {
    method: order.shipping.method,
    addressText: `${street}, ${zipCode}, ${city}`,
    description,
  }
}

function getUserShipping(order) {
  return {
    method: order.shipping.method,
    trackNumber: order.shipping.trackNumber,
    addressText: `${order.shipping.street}, ${order.shipping.postCode}, ${order.shipping.city}`,
  }
}
