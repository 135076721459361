<template>
  <div class="loader">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">{{ $t('loader.title') }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.spinner-border {
  width: 3rem;
  height: 3rem;
}
</style>
