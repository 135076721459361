import _get from 'lodash/get'
import _last from 'lodash/last'
import { DateTime } from 'luxon'

import { GTM } from '@common/config'

import loadSnippet from './loadSnippet'

class DrmaxGtm {
  w: any // window
  layerName: string
  enabled: boolean

  constructor(w, d, s, layerName, gtmId) {
    this.w = w
    this.layerName = layerName
    this.enabled = !!gtmId
    this.w[this.layerName] = this.w[this.layerName] || []
    this.gtmPushEvent({ singlePageApp: true })

    if (this.enabled) {
      loadSnippet(w, d, s, layerName, gtmId)
    }
  }

  gtmPushEvent(gtmEvent) {
    if (GTM.log) {
      console[GTM.log || 'debug']('GTM event', gtmEvent)
    }

    if (this.enabled) {
      try {
        this.w[this.layerName].push(gtmEvent)
      } catch (err) {
        throw new Error(`GTM event push failed: ${err}`)
      }
    }
  }

  update() {
    this.gtmPushEvent({
      event: 'virtualPageview',
    })
  }

  pushOrderStatusEvent(orderStatus, shipping) {
    const pushEvent: any = {
      event: 'orderCheck',
      validity: orderStatus.hasSuccessResponse,
    }

    if (pushEvent.validity) {
      const dateOfOrder = this.getDateOfOrder(orderStatus)
      pushEvent.order = {
        orderNumber: _get(orderStatus, 'data.incrementId'),
        orderStatus: _get(orderStatus, 'data.message'),
        paymentStatus: _get(orderStatus, 'data.isPaid'),
        deliveryType: _get(shipping, 'method'),
        dateOfOrder,
        deliveryAddress: _get(shipping, 'addressText'),
      }
    }
    this.gtmPushEvent(pushEvent)
  }

  getDateOfOrder(orderStatus) {
    let created
    const statusHistory = _get(orderStatus, 'data.statusHistory')
    if (statusHistory) {
      const canceledStatus = statusHistory.find((obj) => obj.status === 'canceled')
      if (canceledStatus) {
        created = _get(canceledStatus, 'created')
      } else {
        created = _get(_last(statusHistory), 'created')
      }

      try {
        const dateTime = DateTime.fromISO(created)
        if (dateTime.isValid) {
          return dateTime.toFormat('yyyy-MM-dd')
        }
      } catch (e) {
        throw new Error(`${e.message} Trying to parse value: ${created}`)
      }
    }
    return undefined
  }
}

export const gtm = new DrmaxGtm(window, document, 'script', 'dataLayerDRMAX', GTM.id)
