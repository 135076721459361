export enum Locale {
  CS = 'cs',
  EN = 'en',
  IT = 'it',
  PL = 'pl',
  RO = 'ro',
  SK = 'sk',
}

export function toLocale(value: string): Locale | undefined {
  const enumValue = Object.values(Locale).find((localeValue) => localeValue === value)
  if (enumValue) return enumValue
  throw new Error(`Unsupported locale: "${value}"`)
}
