import { getCurrentScope } from '@sentry/browser'
import axios, { type AxiosRequestConfig } from 'axios'

export function createAxiosClient(config: AxiosRequestConfig) {
  const client = axios.create(config)

  client.interceptors.request.use(
    (config) => {
      const transactionId = Math.random().toString(36).substr(2, 9)
      const scope = getCurrentScope()
      scope.setTag('transaction_id', transactionId)

      config.headers.set('X-Transaction-ID', transactionId)
      return config
    },
    (error) => {
      error.isAxios = true
      throw error
    },
  )

  client.interceptors.response.use(
    (response) => response,
    (error) => {
      error.isAxios = true
      throw error
    },
  )

  return client
}

export default createAxiosClient
