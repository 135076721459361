import { required, email, numeric } from '@vee-validate/rules'
import { configure, defineRule } from 'vee-validate'

export const init = (i18n) => {
  configure({
    bails: false,
    generateMessage: (context) => i18n.global.t(`validation.${context.rule.name}`, context.value),
  })

  defineRule('required', required)
  defineRule('email', email)
  defineRule('numeric', numeric)
}
