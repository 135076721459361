<template>
  <div id="app" itemscope itemtype="https://schema.org/WebPage">
    <AppHeader :robotEnabled="robotEnabled" />

    <div class="container-fluid px-2 content">
      <div class="main__row">
        <div class="main__col-app">
          <router-view />
        </div>

        <div v-if="robotEnabled" class="main__col-robot">
          <Robot />
        </div>
      </div>
    </div>

    <AppFooter />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

import AppFooter from '@common/components/_ui/AppFooter.vue'
import AppHeader from '@common/components/_ui/AppHeader.vue'
import Robot from '@common/components/_ui/Robot.vue'
import { ROBOT } from '@common/config'

export default defineComponent({
  components: {
    AppHeader,
    AppFooter,
    Robot,
  },

  setup() {
    const robotEnabled = computed<boolean>(() => ROBOT.enabled)

    return {
      robotEnabled,
    }
  },
})
</script>

<style lang="scss">
@import '@/styles/global.scss';
</style>
