import { nextTick } from 'vue'
import {
  createI18n,
  type I18nOptions,
  type IntlDateTimeFormat,
  type IntlDateTimeFormats,
} from 'vue-i18n'

import { Locale } from '@/modules/_common/models/locale'
import { CURRENT_LOCALE } from '@common/config'

const datetimeFormat: IntlDateTimeFormat = {
  date: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  },
  dateHourMinute: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  },
  monthDay: {
    month: 'numeric',
    day: 'numeric',
  },
  hourMinute: {
    hour: '2-digit',
    minute: '2-digit',
  },
}

const datetimeFormats: IntlDateTimeFormats = {
  [Locale.CS]: {
    date: datetimeFormat.date,
    dateHourMinute: datetimeFormat.dateHourMinute,
    monthDay: datetimeFormat.monthDay,
    hourMinute: datetimeFormat.hourMinute,
  },
  [Locale.IT]: {
    date: {
      ...datetimeFormat.date,
      hour: '2-digit',
      minute: '2-digit',
    },
    dateHourMinute: datetimeFormat.dateHourMinute,
    monthDay: datetimeFormat.monthDay,
    hourMinute: datetimeFormat.hourMinute,
  },
  [Locale.PL]: {
    date: datetimeFormat.date,
    dateHourMinute: datetimeFormat.dateHourMinute,
    monthDay: datetimeFormat.monthDay,
    hourMinute: datetimeFormat.hourMinute,
  },
  [Locale.RO]: {
    date: datetimeFormat.date,
    dateHourMinute: datetimeFormat.dateHourMinute,
    monthDay: datetimeFormat.monthDay,
    hourMinute: datetimeFormat.hourMinute,
  },
  [Locale.SK]: {
    date: {
      ...datetimeFormat.date,
      hour: '2-digit',
      minute: '2-digit',
    },
    dateHourMinute: datetimeFormat.dateHourMinute,
    monthDay: datetimeFormat.monthDay,
    hourMinute: datetimeFormat.hourMinute,
  },
}

export const SUPPORT_LOCALES = Object.values(Locale)

export async function initI18n() {
  const options: I18nOptions = {
    legacy: false,
    globalInjection: true,
    locale: CURRENT_LOCALE,
    fallbackLocale: Locale.EN,
    datetimeFormats,
    messages: {},
  }
  const i18n = createI18n(options)
  setI18nLanguage(i18n, options.locale)
  await loadLocaleMessages(i18n, options.locale)
  return i18n
}

export function setI18nLanguage(i18n, locale) {
  i18n.global.locale.value = locale
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  document.querySelector('html').setAttribute('lang', locale)
}

export async function loadLocaleMessages(i18n, locale) {
  // load locale messages with dynamic import
  const messages = await import(`../translations/${locale}.yml`)

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages.default)

  return nextTick()
}
