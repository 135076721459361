<template>
  <span v-if="value">{{ formattedValue }}</span>
  <span v-else>
    <slot>{{ defaultValue }}</slot>
  </span>
</template>

<script lang="ts">
import { DateTime } from 'luxon'
import { defineComponent } from 'vue'

import { TIMEZONE } from '@common/config'

export default defineComponent({
  props: {
    value: {
      type: String,
    },
    format: {
      type: String,
      default: 'date',
    },
    defaultValue: {
      default: null,
    },
  },

  computed: {
    formattedValue(): string {
      const localDateTime = DateTime.fromISO(this.value, { zone: TIMEZONE }).setZone('system', {
        keepLocalTime: true,
      })

      return this.$d(localDateTime.toJSDate(), this.format)
    },
  },
})
</script>
