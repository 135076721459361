<template>
  <aside id="robot">
    <div class="text-center" itemprop="image" itemscope itemtype="https://schema.org/ImageObject">
      <img itemprop="url" src="@/assets/images/robot.svg" alt="Tom" width="232" height="325" />
    </div>

    <p v-html="$t('robot.perex')" class="pt-3 pb-2"></p>

    <ul class="ps-6">
      <li v-for="message of $tm('robot.features')" :key="message" v-html="$rt(message)"></li>
    </ul>

    <p class="py-2">{{ $t('robot.conclusion') }}</p>
  </aside>
</template>
