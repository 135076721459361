import { API_BASE_URL } from '@common/config'

import { createAxiosClient } from './axios'

const api = createAxiosClient({
  baseURL: API_BASE_URL,
})

export default {
  fetchOrder({ orderId, customerEmail }) {
    return api.get(`/orders/${orderId}`, {
      params: {
        customerEmail,
      },
    })
  },
  reinitiatePayment({ orderId, customerEmail }) {
    return api.post(`/orders/${orderId}/payments/reinitiate`, {
      customerEmail,
    })
  },
  updateOrderStatus({ orderId, customerEmail }) {
    return api.put(`/orders/${orderId}/statuses/canceled`, {
      customerEmail,
    })
  },
  updatePaymentMethod({ orderId, customerEmail }) {
    return api.put(`/orders/${orderId}/payment-methods/cash-on-delivery`, {
      customerEmail,
    })
  },
}
