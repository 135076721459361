import { Locale, toLocale } from '@common/models/locale'

// config init
const CONFIG: any = __APP_CONFIG__

export const CURRENT_ENV: string = CONFIG.appInfo.env
export const CURRENT_MUTATION: string = CONFIG.appInfo.mutation
export const IS_LOCAL_ENV = CURRENT_ENV === 'local'
export const CURRENT_LOCALE: Locale = toLocale(CONFIG.locale)
export const CURRENT_THEME: 'default' | 'red' = CONFIG.currentTheme
export const TIMEZONE: string = CONFIG.timeZone

export const API_BASE_URL: string = CONFIG.api.orderTracking
export const ESHOP_URL: string = CONFIG.eshopUrl
export const SENTRY_DSN: string = CONFIG.sentry.dsn
export const SENTRY_ENV: string = CONFIG.sentry.env
export const SENTRY_RELEASE = `${CONFIG.appInfo.name}@${CONFIG.appInfo.version}`

export const GTM: {
  id: string
  disable: boolean
  log?: string
} = CONFIG.gtm

export const GMAPS: {
  apiKey: string
  libraries: (
    | 'core'
    | 'maps'
    | 'places'
    | 'geocoding'
    | 'routes'
    | 'marker'
    | 'geometry'
    | 'elevation'
    | 'streetView'
    | 'journeySharing'
    | 'drawing'
    | 'visualization'
  )[]
  defaultLocation: {
    lat: number
    lng: number
  }
  zoom: {
    default: number
    min: number
  }
} = CONFIG.gmaps

export const ROBOT: {
  enabled: boolean
} = CONFIG.robot

export const SHOW_ONLY_TRANSLATED_STATUSES: boolean = [true, 'true'].includes(
  CONFIG.showOnlyTranslatedStatuses,
)
export const MUTATION_CONFIG = CONFIG.mutation
