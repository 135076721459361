<template>
  <footer class="footer" itemscope itemtype="https://schema.org/WPFooter">
    <div class="container pb-4 pb-sm-6 justify-content-md-between">
      <p class="footer__bottom__copy mt-0 pt-md-4 text-center text-md-left">
        &copy; {{ new Date().getFullYear() }} {{ info.copy }}
      </p>
      <nav
        class="footer-links mt-0 pt-md-2"
        itemscope
        itemtype="https://schema.org/SiteNavigationElement"
      >
        <meta itemprop="name" :content="$t('footer.fastLinks')" />
        <a :href="info.link.privacyProtection" :title="$t('footer.privacyProtection')">{{
          $t('footer.privacyProtection')
        }}</a>
      </nav>
      <div class="footer-contacts mt-0 pt-md-2" itemscope itemtype="https://schema.org/Pharmacy">
        <meta itemprop="name" :content="info.contact.name" />
        <meta itemprop="url" :content="eshopUrl" />
        <a
          :href="`tel:${info.contact.phoneFull}`"
          itemprop="telephone"
          :content="info.contact.phone"
        >
          <svg>
            <use xlink:href="@/assets/images/icons.svg#tel"></use>
          </svg>
          {{ info.contact.phone }}
        </a>
        <a :href="`mailto:${info.contact.email}`" itemprop="email" :content="info.contact.email">
          <svg>
            <use xlink:href="@/assets/images/icons.svg#mail"></use>
          </svg>
          {{ info.contact.email }}
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import { ESHOP_URL, MUTATION_CONFIG } from '@common/config'

export default {
  computed: {
    eshopUrl() {
      return ESHOP_URL
    },
    info() {
      return MUTATION_CONFIG.info
    },
  },
}
</script>
