const Login = () => import(/* webpackChunkName: "advanced" */ './views/Login.vue')
const OrderTracking = () => import(/* webpackChunkName: "advanced" */ './views/OrderTracking.vue')
const CashOnDeliveryPaymentUpdate = () =>
  import(/* webpackChunkName: "advanced" */ './views/actions/CashOnDeliveryPaymentUpdate.vue')
const OrderCancellation = () =>
  import(/* webpackChunkName: "advanced" */ './views/actions/OrderCancellation.vue')
const PaymentReinitialization = () =>
  import(/* webpackChunkName: "advanced" */ './views/actions/PaymentReinitialization.vue')

export const login = {
  path: '/',
  component: Login,
}

export const orderTracking = {
  path: '/:id',
  name: 'order',
  component: OrderTracking,
}

export const cashOnDeliveryPaymentUpdate = {
  path: '/:id/cash-on-delivery-payment-update',
  name: 'cashOnDeliveryPaymentUpdate',
  component: CashOnDeliveryPaymentUpdate,
}

export const orderPaymentReinitializationV1 = {
  path: '/:id/payments', // older emails from M2 are using this URL
  redirect: { name: 'paymentReinitialization' },
}

export const orderPaymentReinitialization = {
  path: '/:id/reinitiate-payment',
  name: 'paymentReinitialization',
  component: PaymentReinitialization,
}

export const orderCancellation = {
  path: '/:id/cancel',
  name: 'orderCancellation',
  component: OrderCancellation,
}

export default [
  login,
  cashOnDeliveryPaymentUpdate,
  orderPaymentReinitializationV1,
  orderPaymentReinitialization,
  orderCancellation,
  orderTracking,
]
